import React, { useEffect, useState } from 'react';
import Slider from "react-slick";

import PageTitle from '../../components/global/PageTitle';
import ProductInfoTabs from '../../components/products/ProductInfoTabs';
import QuickView from '../../components/products/QuickView';
import RecentSingleProducts from '../../components/products/RecentSingleProducts';

import Layout from '../../components/global/Layout';
import { useLocation, useNavigate } from 'react-router-dom';
import { useProductsContext } from '../../providers/ProductsProvider';
import { useGlobalContext } from '../../providers/GlobalProvider';
import { PUBLIC_CDN_URL } from '../../defs';


const SingleVerticalThumbnail = () => {

    /**
     * states
     */
    const [productCount, setProductCount] = useState(1);
    const [showQuickView, setShowQuickView] = useState(false);
    const [quickViewData, setQuickViewData] = useState({});
    const [data, setData] = useState(null)

    const { layout } = useGlobalContext()
    const { products } = useProductsContext()

    const navigate = useNavigate()

    const search = useLocation().search;
    const id = parseInt(new URLSearchParams(search).get("id"));

    useEffect(() => {
        if (id === null || isNaN(id)) {
            navigate('/404', { replace: true })
        } else {
            if (products.length > 0) {
                const product = products.find(item => id === item.id);
                if (product === undefined) {
                    navigate('/404', { replace: true })
                } else {
                    setData(product)
                }
            }
        }
        // eslint-disable-next-line
    }, [products]);


    /**
     * Handle Product Count
     */
    const HandleProductCount = (e, data) => {
        e.preventDefault();
        if (data === 'plus') {
            setProductCount(productCount + 1);
        } else {
            if (productCount > 1) {
                setProductCount(productCount - 1);
            } else {
                setProductCount(1);
            }
        }
    };

    /**
     * Handel Quick View Data
     */
    const HandelQuickViewData = (e, item) => {
        e.preventDefault();
        setShowQuickView(!showQuickView);
        setQuickViewData(item);
    };

    /**
     * Handel Quick View Close
     */
    const HandelQuickViewClose = (e) => {
        e.preventDefault();
        setShowQuickView(false);
        setQuickViewData({});
    };

    /**
     * slider ettings
     */
    const settings = {
        customPaging: function (i) {
            return (
                <a href='/'>
                    <img src={process.env.PUBLIC_URL + `assets/images/shop/img-${i + 1}.jpg`} alt='' />
                </a>
            );
        },
        dots: true,
        dotsClass: "slick-dots slick-thumb slider-nav",
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        data === null ? <></> :
            <Layout>
                {showQuickView
                    ? <QuickView
                        data={quickViewData}
                        onQuickViewCloseClick={HandelQuickViewClose}
                    />
                    : ''
                }


                <PageTitle name={layout.product_page.title} />


                {/* start shop-single-section */}
                <section className="shop-single-section shop-single-vertical-thumb section-padding">
                    <div className="container-1410">
                        <div className="row">
                            <div className="col col-md-7">
                                <div className="shop-single-slider vertical-thumbnail">
                                    <Slider {...settings}>
                                        {
                                            data.gallery.map((item, index) => (
                                                <div key={index}>
                                                    <img src={PUBLIC_CDN_URL + item} alt='' />
                                                </div>
                                            ))
                                        }
                                    </Slider>
                                    <div className="slider-nav"></div>
                                </div>
                            </div>
                            <div className="col col-md-5">
                                <div className="product-details">
                                    <h2>{data.title}</h2>
                                    <div className="price">
                                        <span className="current">{layout.common.currency_symbol}{data.price}</span>
                                        <span className="old">{layout.common.currency_symbol}{data.oldPrice}</span>
                                    </div>
                                    <div className="rating">
                                        <i className="fi flaticon-star" />
                                        <i className="fi flaticon-star" />
                                        <i className="fi flaticon-star" />
                                        <i className="fi flaticon-star" />
                                        <i className="fi flaticon-star-social-favorite-middle-full" />
                                        {
                                            (data.reviews.length > 0) ? <span>({layout.product_page.customer_review_label.replace('{0}', data.reviews.length)})</span> : <></>
                                        }
                                    </div>
                                    <p>{data.shortDescription}</p>
                                    <div className="product-option">
                                        <form className="form">
                                            <div className="product-row">
                                                <div className="touchspin-wrap">
                                                    <button
                                                        onClick={(e) => {
                                                            HandleProductCount(e, 'plus')
                                                        }} id="slider-thumbnail-touchspin-up" className="btn btn-default "
                                                        type="button"><i className="glyphicon glyphicon-chevron-up"></i>
                                                    </button>
                                                    <button
                                                        onClick={(e) => {
                                                            HandleProductCount(e, 'minus')
                                                        }}
                                                        id="slider-thumbnail-touchspin-down" className="btn btn-default "
                                                        type="button"><i className="glyphicon glyphicon-chevron-down"></i>
                                                    </button>
                                                    <input readOnly className="product-count" type="text"
                                                        value={productCount} name="product-count" />
                                                </div>

                                                <div>
                                                    <button type="submit">{layout.common.add_to_cart_button_label}</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="thb-product-meta-before">
                                        {/* <div className="add-to-wishlist">
                                            <a href="/" className="add_to_wishlist">
                                                <i className="pe-7s-like" />
                                                <span>Add To Wishlist</span>
                                            </a>
                                        </div> */}
                                        <div className="product_meta">
                                            <span className="sku_wrapper">{layout.common.sku_label} <span
                                                className="sku">{data.sku}</span></span>
                                            <span className="posted_in">
                                                {layout.common.category_label}:
                                                {
                                                    <a href={'/category?name=' + data.category}> {data.category}</a>
                                                }
                                            </span>
                                            <span className="tagged_as">{layout.common.tags_label}:
                                                {
                                                    data.tags.split(",").map((item, index) =>
                                                        <a key={index}
                                                            href={'/category?tag=' + item}> {item} {data.tags.split(",").length - 1 === index ? '' : ','}
                                                        </a>
                                                    )
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* end col */}
                        </div>
                        {/* end row */}
                        <div className="row">
                            <div className="col col-xs-12">
                                <ProductInfoTabs item={data} />
                            </div>
                        </div>
                        {/* end row */}
                        <div className="row">
                            <div className="col col-xs-12">
                                <RecentSingleProducts onQuickViewClick={HandelQuickViewData} />
                            </div>
                        </div>
                    </div>
                    {/* end of container */}
                </section>
                {/* end of shop-single-section */}

            </Layout>
    );
}

export default SingleVerticalThumbnail