import React from 'react';
import { useGlobalContext } from '../../providers/GlobalProvider';


const CompanyWidget = () => {
    const { layout } = useGlobalContext();

    return (
        <>
            <div className="widget company-widget">
                <h3>{layout.footer.company.title}</h3>
                <ul>
                    {
                        layout.footer.company.links.map((item, index) => (
                            <li key={index}><a href={item.link}>{item.label}</a></li>
                        ))
                    }
                </ul>
            </div>
        </>
    )
}

export default CompanyWidget