import React from 'react';
import styled from 'styled-components';


const Feature1Item = ({ icon, title, text }) => {
    return <>
        <Wrapper>
            <div className="icon">
                {/* <i className={icon} /> */}
                <i>
                    <img alt='' src={process.env.PUBLIC_URL + icon} width='50px' />
                </i>
            </div>
            <div className="info">
                <h3>{title}</h3>
                <p>{text}</p>
            </div>
        </Wrapper>
    </>;
};

export default Feature1Item;

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    
`