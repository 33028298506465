import React, { useState } from 'react';
import ClientReviewItem from "./ClientReviewItem";
import AddReview from "./AddReview";
import { useGlobalContext } from '../../providers/GlobalProvider';

const ProductInfoTabs = ({ item }) => {
    const [openTab, setOpenTab] = useState(1);
    const { layout } = useGlobalContext()

    const HandleOpenTabStatus = (event, data) => {
        event.preventDefault();
        setOpenTab(data);
    };

    return <>
        <div className="single-product-info">
            {/* Nav tabs */}
            <ul className="nav nav-tabs" role="tablist">
                <li onClick={(event) => {
                    HandleOpenTabStatus(event, 1)
                }} className={" " + (openTab === 1 ? 'active' : '')}><a href="#01" data-toggle="tab">
                        {layout.product_page.tab_description_label}
                    </a>
                </li>
                <li onClick={(event) => {
                    HandleOpenTabStatus(event, 2)
                }} className={" " + (openTab === 2 ? 'active' : '')}><a href="#02" data-toggle="tab">
                        {layout.product_page.tab_review_label.replace('{0}', (item.reviews.length < 10) ? '0' + item.reviews.length : item.reviews.length)}
                    </a>
                </li>
            </ul>
            {/* Tab panes */}
            <div className="tab-content">
                <div role="tabpanel" className={"tab-pane fade " + (openTab === 1 ? 'in active' : '')} id="01">
                    <p> {item.description} </p>
                </div>
                <div role="tabpanel" className={"tab-pane fade " + (openTab === 2 ? 'in active' : '')} id="02">
                    <div className="row">
                        <div className="col col-xs-12">
                            {item.reviews.map((item, index) => (
                                <ClientReviewItem key={index}
                                    img={process.env.PUBLIC_URL + item.pic}
                                    time={item.dateTime}
                                    text={item.coment} />
                            ))}
                        </div>
                        {/* end col */}
                        <div className="col col-xs-12 review-form-wrapper">
                            <AddReview />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
};

export default ProductInfoTabs;