import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PUBLIC_CDN_URL } from '../defs';
import { useApiContext } from './ApiProvider';
import { useCartContext } from './CartProvider';
import { useStatsContext } from './StatsProvider';

const OrderContext = createContext()

const OrderProvider = ({ children }) => {
    const [orderId, setOrderId] = useState(0);
    const [isOrderCompleting, setIsOrderCompleting] = useState(false);

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');

    const { createOrderRequest } = useApiContext()
    const { shippingPrice, discountPrice, getSubtotalPrice, clearCartData } = useCartContext()
    const { callPurchase } = useStatsContext()

    const navigate = useNavigate()

    const canOrder = () => {
        return (name !== '' && phone !== '' && address !== '')
    }

    const orderCompleteHandler = (e, data) => {
        e.preventDefault();

        const products = [];
        for (var i = 0; i < data.length; i++) {

            const item = {
                id: data[i].id,
                productName: data[i].name,
                productCategoryID: data[i].productData.categoryId,
                productPrice: data[i].price,
                productSize: data[i].size,
                productColor: data[i].color,
                productImg: PUBLIC_CDN_URL + data[i].img,
                qty: data[i].qty.toString()
            }
            products.push(item);
        }

        const subtotalPrice = getSubtotalPrice();
        const totalPrice = ((subtotalPrice - discountPrice) + shippingPrice)

        setIsOrderCompleting(true);

        createOrderRequest({
            orderType: "common",
            customerName: name,
            customerEmail: 'empty',
            customerPhone: phone,
            customerAddress: address,
            customerNotes: 'empty',
            subtotalPrice: subtotalPrice.toString(),
            discountPrice: discountPrice.toString(),
            shippingPrice: shippingPrice.toString(),
            totalPrice: totalPrice.toString(),
            products: products
        },
            (data) => {
                setOrderId(data.id)
                clearCartData();
                setIsOrderCompleting(false);

                callPurchase(totalPrice / 10000)
            },
            (error) => {
                console.log(error)
                setIsOrderCompleting(false);
                navigate('/404', { replace: true })
            }
        )

        navigate('/thank-you-page' + getPurchaseResultId(products), { replace: true })
    }

    const getPurchaseResultId = (products) => {
        let sameCategory = true
        let prevProduct = products[0]
        let currentProduct;
        for (let i = 1; i < products.length; i++) {
            currentProduct = products[i]
            if (prevProduct.productCategoryID !== currentProduct.productCategoryID) {
                sameCategory = false
            }
        }

        let result = ""
        if (sameCategory) {
            result = "?result=" + products[0].productCategoryID
        }

        return result;
    }

    const contextValue = {
        orderId,
        isOrdering: isOrderCompleting,

        name,
        phone,
        address,

        canOrder,
        orderCompleteHandler,
        setName,
        setPhone,
        setAddress
    }

    return (
        <OrderContext.Provider value={contextValue}>
            {children}
        </OrderContext.Provider>
    );
};

export const useOrderContext = () => useContext(OrderContext)

export default OrderProvider;
