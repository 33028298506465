import React from 'react';
import NewsletterWidget from "../widget/NewsletterWidget";
import ContactWidget from "../widget/ContactWidget";
import CompanyWidget from "../widget/CompanyWidget";
import PaymentWidget from "../widget/PaymentWidget";
import { useGlobalContext } from '../../providers/GlobalProvider';


const Footer = () => {
    const { layout } = useGlobalContext()

    return (
        <>
            {/* start site-footer */}
            <footer className="site-footer">
                <div className="container-1410">
                    <div className="row widget-area">
                        <div className="col-lg-4 col-xs-6  widget-col about-widget-col">
                            <NewsletterWidget />
                        </div>
                        <div className="col-lg-4 col-xs-6 widget-col">
                            <ContactWidget />
                        </div>
                        <div className="col-lg-2 col-xs-6 widget-col">
                            <CompanyWidget />
                        </div>
                        <div className="col-lg-2 col-xs-6 widget-col">
                            <PaymentWidget />
                        </div>
                    </div>
                </div>
                {/* end container */}
                <div className="lower-footer">
                    <div className="container-1410">
                        <div className="row">
                            <div className="col-xs-12">
                                <div className="lower-footer-inner clearfix">
                                    <div>
                                        <p>{layout.footer.copyright_label}</p>
                                    </div>
                                    <div className="social">
                                        <ul className="clearfix">
                                            {
                                                layout.footer.social_links.map((item, index) => (
                                                    <li key={index}>
                                                        <a href={item.link} title={item.title} rel="noopener noreferrer" target="_blank">
                                                            {item.label}
                                                        </a>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                    <div className="extra-link">
                                        <ul>
                                            {
                                                layout.footer.extra_links.map((item, index) => (
                                                    <li key={index}><a href={item.link}>{item.label}</a></li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* end site-footer */}
        </>
    )
}

export default Footer