import React from 'react';
import Layout from '../../components/global/Layout';
import PageTitle from '../../components/global/PageTitle';

const Category = () => {
    return (
        <Layout>
            {/* <FeaturedProducts /> */}
            <PageTitle name="Catagory" />
            <div>Test 4</div>
        </Layout>
    )
};

export default Category;
