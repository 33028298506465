import React, { useEffect, useState } from 'react';
import './pagination.css'

const Pagination = ({ page, count, onChange, maxPages = 7 }) => {

    const [pages, setPages] = useState([])

    const handleOnClick = (e, value) => {
        e.preventDefault()
        onChange(value)
    }

    const handleArrow = (e, direction) => {
        e.preventDefault()

        if (direction === "left") {
            onChange(page - 1)
        } else {
            onChange(page + 1)
        }
    }

    const createPaginationArray = () => {
        const array = []

        if (count > maxPages) {
            const isLeft = (page < 4)
            const isRight = (page > count - 3)

            for (let i = 0; i < maxPages; i++) {
                array.push("...")
            }

            if (isLeft) {
                let tmpCount = 1
                array[array.length - 1] = count
                array[array.length - 2] = "..."
                for (let i = 0; i < array.length - 2; i++) {
                    array[i] = tmpCount;
                    tmpCount++;
                }
            } else if (isRight) {
                let tmpCount = count
                array[0] = 1
                array[1] = "..."
                for (let i = array.length - 1; i >= 2; i--) {
                    array[i] = tmpCount;
                    tmpCount--;
                }
            } else {
                array[0] = 1
                array[2] = page - 1
                array[3] = page
                array[4] = page + 1
                array[6] = count
            }

            setPages(array)
        } else {
            for (let i = 0; i < count; i++) {
                array.push(i + 1)
            }

            setPages(array)
        }
    }

    useEffect(() => {
        createPaginationArray()
        // eslint-disable-next-line
    }, [page])


    return (
        <>
            <div className="pagination-wrapper">
                <ul className="pg-pagination">
                    <li>
                        <a href="/" aria-label="Previous" className={page === 1 ? "inactive-link" : ""} onClick={(e) => handleArrow(e, "left")}>
                            <i className="fi flaticon-back" />
                        </a>
                    </li>

                    {pages.map((item, index) => (
                        <li key={index} className={page === item ? "active" : ""}>
                            <a href="/" onClick={(e) => handleOnClick(e, item)}>{item}</a></li>
                    ))}

                    <li >
                        <a href="/" aria-label="Next" className={page === count ? "inactive-link" : ""} onClick={(e) => handleArrow(e, "right")}>
                            <i className="fi flaticon-next" />
                        </a>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default Pagination
