import React, { useEffect, useState } from 'react';
import { useGlobalContext } from '../../providers/GlobalProvider';
import { useProductsContext } from '../../providers/ProductsProvider';
import { PUBLIC_CDN_URL } from '../../defs'
import parse from 'html-react-parser'
import styled from 'styled-components';
import { useCartContext } from '../../providers/CartProvider';
import { useStatsContext } from '../../providers/StatsProvider';

const QuickView = ({ data }) => {
    const [count, setCount] = useState("1");

    const { layout, handelQuickViewClose, formattedPriceWithCurrency } = useGlobalContext()
    const { getCategoryNameById } = useProductsContext()
    const { addToCart } = useCartContext()
    const { callViewContent } = useStatsContext()

    const handleAddToCart = (e) => {
        e.preventDefault();
        addToCart(data, parseInt(count));

        handelQuickViewClose(e)
    }

    useEffect(() => {
        if (parseInt(count) <= 0 || count === "") {
            setCount("1")
        }
    }, [count]);

    useEffect(() => {
        callViewContent(data)
        // eslint-disable-next-line 
    }, [])



    return <>
        <div className="quick-view-single-product activve-quick-view-single-product">
            <div className="view-single-product-inner clearfix">
                <button className="btn quick-view-single-product-close-btn" onClick={handelQuickViewClose}><i
                    className="pe-7s-close-circle" /></button>
                <div className="img-holder">
                    <img loading="lazy" src={PUBLIC_CDN_URL + data.mainImg} alt="" />
                </div>
                <div className="product-details">
                    <h4>{data.title}</h4>
                    <div className="price">
                        <span className="current">{formattedPriceWithCurrency(data.price)}</span>
                        {parseInt(data.price) < parseInt(data.oldPrice) ?
                            <span className="old">{formattedPriceWithCurrency(data.oldPrice)}</span> : ''
                        }
                    </div>
                    <div className="rating">
                        <i className="fi flaticon-star" />
                        <i className="fi flaticon-star" />
                        <i className="fi flaticon-star" />
                        <i className="fi flaticon-star" />
                        <i className="fi flaticon-star-social-favorite-middle-full" />
                        <span>{layout.common.customer_review_label.replace('{0}', data.reviews.length)}</span>
                    </div>
                    <p>{parse(data.shortDescription)}</p>
                    <div className="product-option">
                        <form className="form">
                            <div className="product-row">
                                <div >
                                    <input className="product-count" type="number" min={1} value={count}
                                        onChange={(e) => { setCount(e.target.value) }} />
                                </div>
                                <div >
                                    <AddToCartButton type="submit" onClick={handleAddToCart}>{layout.common.add_to_cart_button_label}</AddToCartButton>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="thb-product-meta-before">
                        {/* <div className="add-to-wishlist">
                            <a href="/" className="add_to_wishlist">
                                <i className="pe-7s-like" />
                                <span>{layout.common.add_to_wishlist_button_label}</span>
                            </a>
                        </div> */}
                        <div className="product_meta">
                            <span className="sku_wrapper">
                                {layout.common.sku_label}: <span className="sku">{data.id.toString().padStart(6, '0')}</span></span>
                            <span className="posted_in">
                                {layout.common.category_label}:
                                {
                                    <a href={'/category?id=' + data.categoryId}> {getCategoryNameById(data.categoryId)}</a>
                                }
                            </span>
                            {/* <span className="tagged_as">
                                {layout.common.tags_label}:
                                {
                                    data.tags.split(",").map((item, index) =>
                                        <a key={index}
                                            href={'/category?tag=' + item}> {item} {data.tags.split(",").length - 1 === index ? '' : ','}</a>
                                    )
                                }
                            </span> */}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    </>;
};

export default QuickView;

const AddToCartButton = styled.button`
    transition: none;
    &:active{
        background-color: #c2572b;
    }
`