import React from 'react';
import styled from 'styled-components';
import { PUBLIC_CDN_URL } from '../../defs';
import { useCartContext } from '../../providers/CartProvider';
import { useGlobalContext } from '../../providers/GlobalProvider';


const CartItem = ({ data }) => {
    const { layout, formattedPriceWithCurrency } = useGlobalContext()
    const { removeFromCart, icrementQtyHandler, decrementQtyHandler } = useCartContext()

    return <>
        <tr className="cart_item">
            <td className="product-remove">
                <ActionLink onClick={() => removeFromCart(data)} title={layout.cart.orders_table.remove_item_tooltip} data-product_id={data.id}>×</ActionLink>
            </td>
            <td className="product-thumbnail">
                <a href={data.link}>
                    <img width={57} height={70} src={PUBLIC_CDN_URL + data.img} className="attachment-shop_thumbnail size-shop_thumbnail wp-post-image" alt="#" />
                </a>
            </td>
            <td className="product-name" data-title="Product">
                <a href={data.link}>{data.name}</a></td>
            <td className="product-price" data-title="Price">
                <span className="woocommerce-Price-amount amount">
                    <span className="woocommerce-Price-currencySymbol">{formattedPriceWithCurrency(data.price)}</span>
                </span>
            </td>
            <td className="product-quantity" data-title="Quantity">
                <div className="quantity">
                    <button className="btn btn-default bootstrap-touchspin-up" onClick={() => icrementQtyHandler(data)} type="button"><i className="glyphicon glyphicon-chevron-up"></i></button>
                    <input type="number" value={data.qty} onChange={() => { }} title="Qty" className="product-count input-text qty text" />
                    <button className="btn btn-default bootstrap-touchspin-down" onClick={() => decrementQtyHandler(data)} type="button"><i className="glyphicon glyphicon-chevron-down"></i></button>
                </div>
            </td>
            <td className="product-subtotal" data-title="Total">
                <span className="woocommerce-Price-amount amount">
                    <span className="woocommerce-Price-currencySymbol">{formattedPriceWithCurrency(data.totalPrice)}</span>
                </span>
            </td>
        </tr>
    </>;
};

export default CartItem;


const ActionLink = styled.span`
    width: 18px;
    height: 18px;
    display: inline-flex;
    justify-content: center;
    text-align: center;
    line-height: 20px;
    color: #fff;
    border-radius: 50%;
    background: #dbdbdb;
    cursor: pointer;

    &:hover{
        background-color: #000;
    }
`