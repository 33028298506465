import React from 'react';
import './navbarRight.css'
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from '../../providers/GlobalProvider';
import { useCartContext } from '../../providers/CartProvider';
import styled from 'styled-components';
import MiniCartItem from './MiniCartItem';


/**
 * right side of header include minicart, and buttons
 * @returns {*}
 * @constructor
 */
function HeaderRight() {
    const { handelMiniCartStatus, showMiniCart, layout, formattedPriceWithCurrency } = useGlobalContext();
    const { data, getCartTotalItems, getSubtotalPrice } = useCartContext()

    const navigate = useNavigate()

    const onNavClick = (link) => {
        handelMiniCartStatus();

        navigate(link, { replace: true })
    }

    return (
        <>
            <div className="header-right">
                {/* <div className="my-account-link">
                    <Link className="" to="/my-account">
                        <i className="icon-user" />
                    </Link>
                </div> */}
                {/* <div className="wishlist-box">
                    <a href="#"><i className="icon-heart-shape-outline"/></a>
                </div> */}
                <div className="mini-cart">
                    <button className="cart-toggle-btn" onClick={getCartTotalItems() > 0 ? handelMiniCartStatus : null}>
                        <i className="icon-large-paper-bag" />
                        {
                            getCartTotalItems() > 0 ?
                                <span className="cart-count">{getCartTotalItems()}</span> :
                                <></>
                        }

                    </button>
                    <div className={"mini-cart-content " + (showMiniCart ? 'mini-cart-content-toggle' : '')}>
                        <div className="mini-cart-items">
                            {
                                data.map((item, index) => (
                                    <MiniCartItem key={index} item={item} onNavClick={onNavClick} />
                                ))
                            }
                        </div>
                        <div className="mini-cart-action clearfix">
                            <span className="mini-checkout-price">{layout.header.header_right.mini_cart.subtotal_label} {formattedPriceWithCurrency(getSubtotalPrice())}</span>
                            <ActionButton isCheckout={false} onClick={() => onNavClick("/cart")}>
                                {layout.header.header_right.mini_cart.view_cart_label}
                            </ActionButton>
                            <ActionButton isCheckout={true} onClick={() => onNavClick("/checkout")}>
                                {layout.header.header_right.mini_cart.checkout_label}
                            </ActionButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HeaderRight;

const ActionButton = styled.span`
    font-family: "futura-pt-demi", sans-serif;
    background: ${props => props.isCheckout ? '#545454' : '#000'};
    width: 100%;
    font-size: 12px;
    font-size: 0.8rem;
    font-weight: 500;
    font-weight: 500;
    padding: 12px 20px;
    color: #fff;
    color: white;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 10px;
    cursor: pointer;

    &:hover{
        background-color: #c2572b;
        color: #fff;
    }
`