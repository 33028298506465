import React, {Fragment} from 'react';

/**
 * About Widget
 * @returns {*}
 * @constructor
 */
function AboutWidget() {

    return (
        <Fragment>
            <div className="widget about-widget">
                <h3>About</h3>
                <p>Aowards the viewer hregor then turned to look out the window at the dull weather towards the viewer
                    regor then turned to look </p>
            </div>
        </Fragment>
    );
}

export default AboutWidget;