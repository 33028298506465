import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useCartContext } from '../../providers/CartProvider';
import { useGlobalContext } from '../../providers/GlobalProvider';


const CalculatedShipping = () => {
    const [showShippingCalculator, setShowShippingCalculator] = useState(false);

    /**
     * handle Shipping Calculator state
     * @param event
     */
    const handleShippingCalculatorStatus = (event) => {
        event.preventDefault();
        setShowShippingCalculator(!showShippingCalculator);
    };


    const { layout, formattedPriceWithCurrency } = useGlobalContext()
    const {
        shippingPrice,
        isFreeShipping,
        getSubtotalPrice,
        shippingSelectData,
        shippingType,
        setShippingType,
        discountPrice
    } = useCartContext()
    const navigate = useNavigate()

    return <>
        <div className="cart_totals calculated_shipping">
            <h2>{layout.cart.total_table.title}</h2>
            <table className="shop_table shop_table_responsive">
                <tbody>
                    <tr className="cart-subtotal">
                        <th>{layout.cart.total_table.subtotal_label}</th>
                        <td data-title="Subtotal">
                            <span className="woocommerce-Price-amount amount">
                                <span className="woocommerce-Price-currencySymbol">
                                    {formattedPriceWithCurrency(getSubtotalPrice())}
                                </span>
                            </span>
                        </td>
                    </tr>
                    {discountPrice === 0 ? (
                        <></>
                    ) : (
                        <tr className="cart-subtotal">
                            <th>{layout.cart.total_table.discount_label}</th>
                            <td data-title="Subtotal">
                                <span className="woocommerce-Price-amount amount">
                                    <span className="woocommerce-Price-currencySymbol">
                                        {formattedPriceWithCurrency(-discountPrice)}
                                    </span>
                                </span>
                            </td>
                        </tr>
                    )}

                    <tr className="shipping">
                        <th> {layout.cart.total_table.shipping_label}</th>
                        <td data-title="Shipping">
                            {isFreeShipping ?
                                (
                                    <>
                                        {layout.common.free_shipping_label}
                                    </>
                                ) : (
                                    <>
                                        {formattedPriceWithCurrency(shippingPrice)}<br />
                                        {shippingType.label}
                                        <form className="woocommerce-shipping-calculator">
                                            <p>
                                                <a onClick={handleShippingCalculatorStatus} href="/"
                                                    className="shipping-calculator-button">
                                                    <b>{layout.cart.total_table.change_shipping_label}</b>
                                                </a>
                                            </p>
                                            {
                                                showShippingCalculator ?
                                                    (
                                                        <select value={shippingType.value} onChange={(e) => {
                                                            setShippingType(shippingSelectData[e.target.selectedIndex])
                                                            setShowShippingCalculator(!showShippingCalculator);
                                                        }}>
                                                            {
                                                                shippingSelectData.map((item, index) => (
                                                                    <option key={index} value={item.value}>{item.label}</option>
                                                                )
                                                                )
                                                            }

                                                        </select>
                                                    ) : (
                                                        <div></div>
                                                    )
                                            }
                                        </form>
                                    </>
                                )
                            }

                        </td>
                    </tr>
                    <tr className="order-total">
                        <th> {layout.cart.total_table.total_label}</th>
                        <td>
                            <strong>
                                <span
                                    className="woocommerce-Price-amount amount">
                                    <span className="woocommerce-Price-currencySymbol">
                                        {formattedPriceWithCurrency(getSubtotalPrice() - discountPrice + shippingPrice)}
                                    </span>
                                </span>
                            </strong>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="wc-proceed-to-checkout">
                <CheckoutButton disabled={!(getSubtotalPrice() > 0)} onClick={() => { navigate('/checkout', { raplace: true }) }}>
                    {layout.cart.total_table.checkout_button_label}
                </CheckoutButton>
            </div>
        </div >
    </>;
};

export default CalculatedShipping;

const CheckoutButton = styled.button`
    font-family: "futura-pt-demi", sans-serif;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    font-size: 12px;
    font-size: 0.8rem;
    font-weight: 500;
    padding: 14px 35px;
    text-transform: uppercase;
    letter-spacing: 2px;
    display: inline-block;
    border: 0;
    border-radius: 0;
    outline: 0;

    @media screen and (max-width: 991px){
        margin-top: 15px;
    }

    &:hover{
        background: rgba(0, 0, 0, 0.6);
    }

    &:disabled{
        background: rgba(0, 0, 0, 0.3);
    }
`