import React from 'react';
import PageTitle from '../../components/global/PageTitle';

import ShippingFields from './ShippingFields';
import Layout from '../../components/global/Layout';
import { useGlobalContext } from '../../providers/GlobalProvider';
import { useCartContext } from '../../providers/CartProvider';
import styled from 'styled-components';
import { useOrderContext } from '../../providers/OrderProvider';

const Checkout = () => {

    const { layout, formattedPriceWithCurrency } = useGlobalContext();
    const { data, shippingPrice, getSubtotalPrice, isFreeShipping, discountPrice } = useCartContext()
    const { canOrder, orderCompleteHandler } = useOrderContext()

    return (
        <Layout>
            <PageTitle name={layout.checkout.title} />

            {/* start checkout-section */}
            <section className="checkout-section section-padding">
                <div className="container-1410">
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="woocommerce">
                                <form name="checkout" className="checkout woocommerce-checkout">
                                    <div className="col2-set" id="customer_details">
                                        {/* <BillingFields /> */}
                                        <ShippingFields />
                                    </div>
                                    <h3 id="order_review_heading">{layout.checkout.order_table.title}</h3>
                                    <div id="order_review" className="woocommerce-checkout-review-order">
                                        <table className="shop_table woocommerce-checkout-review-order-table">
                                            <thead>
                                                <tr>
                                                    <th className="product-name">{layout.checkout.order_table.product_label}</th>
                                                    <th className="product-total">{layout.checkout.order_table.price_label}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data.map((item, index) => (
                                                        <tr key={index} className="cart_item">
                                                            <td className="product-name">
                                                                {item.name} &nbsp; <strong className="product-quantity">×
                                                                    {item.qty}</strong></td>
                                                            <td className="product-total">
                                                                <span className="woocommerce-Price-amount amount"><span
                                                                    className="woocommerce-Price-currencySymbol">{formattedPriceWithCurrency(item.totalPrice)}</span></span>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr className="cart-subtotal">
                                                    <th>{layout.checkout.order_table.subtotal_label}</th>
                                                    <td><span className="woocommerce-Price-amount amount"><span
                                                        className="woocommerce-Price-currencySymbol">{formattedPriceWithCurrency(getSubtotalPrice())}</span></span>
                                                    </td>
                                                </tr>
                                                <tr className="shipping">
                                                    <th>{layout.checkout.order_table.shipping_label}</th>
                                                    <td data-title="Shipping">
                                                        {isFreeShipping ? layout.common.free_shipping_label : formattedPriceWithCurrency(shippingPrice)}
                                                        <input type="hidden" name="shipping_method[0]" data-index={0}
                                                            id="shipping_method_0" defaultValue="free_shipping:1"
                                                            className="shipping_method" />
                                                    </td>
                                                </tr>
                                                <tr className="shipping">
                                                    <th>{layout.checkout.order_table.discount_label}</th>
                                                    <td data-title="Shipping">
                                                        {formattedPriceWithCurrency(-discountPrice)}
                                                        <input type="hidden" name="shipping_method[0]" data-index={0}
                                                            id="shipping_method_0" defaultValue="free_shipping:1"
                                                            className="shipping_method" />
                                                    </td>
                                                </tr>
                                                <tr className="order-total">
                                                    <th>{layout.checkout.order_table.total_label}</th>
                                                    <td><strong><span className="woocommerce-Price-amount amount"><span
                                                        className="woocommerce-Price-currencySymbol">{formattedPriceWithCurrency(getSubtotalPrice() - discountPrice + shippingPrice)}</span></span></strong>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                        <div id="payment" className="woocommerce-checkout-payment">

                                            <div className="form-row place-order">
                                                <OrderButton disabled={!canOrder()} onClick={(e) => orderCompleteHandler(e, data)}>{layout.checkout.order_table.buy_button_label}</OrderButton>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end checkout-section */}

        </Layout>
    );
}

export default Checkout


const OrderButton = styled.button`
    font-family: "futura-pt-demi", sans-serif;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    font-size: 12px;
    font-size: 0.8rem;
    font-weight: 500;
    padding: 14px 35px;
    text-transform: uppercase;
    letter-spacing: 2px;
    display: inline-block;
    border: 0;
    border-radius: 0;
    outline: 0;

    @media screen and (max-width: 991px){
        margin-top: 15px;
    }

    &:hover{
        background: rgba(0, 0, 0, 0.6);
    }

    &:disabled{
        background: rgba(0, 0, 0, 0.3);
    }
`