import React from 'react';
import { useGlobalContext } from '../../providers/GlobalProvider';

const PaymentWidget = () => {
    const { layout } = useGlobalContext()

    return (
        <>
            <div className="widget payment-widget">
                <h3>{layout.footer.payment_and_shipping.title}</h3>
                <ul>
                    {layout.footer.payment_and_shipping.links.map((item, index) => (
                        <li key={index}><a href={item.link}>{item.label}</a></li>
                    ))}
                </ul>
            </div>
        </>
    )
}

export default PaymentWidget