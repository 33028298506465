import React from 'react';

const Preloader = () => {
    return (
        <>
            {/* start preloader */}
            <div className="preloader">
                <div className="sk-chase">
                    <div className="sk-chase-dot" />
                    <div className="sk-chase-dot" />
                    <div className="sk-chase-dot" />
                    <div className="sk-chase-dot" />
                    <div className="sk-chase-dot" />
                    <div className="sk-chase-dot" />
                </div>
            </div>
            {/* end preloader */}
        </>
    );;
};

export default Preloader;