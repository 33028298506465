import React from 'react';
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import { PUBLIC_CDN_URL } from '../../defs';
import ProductPriceAmount from '../products/ProductPriceAmount';
import ShopAction from '../products/ShopAction';
import parse from 'html-react-parser'
import DiscountBadge from '../products/DiscountBadge';
import styled from 'styled-components'
import { useCartContext } from '../../providers/CartProvider';
import { useGlobalContext } from '../../providers/GlobalProvider';

const Products = ({ products, ordering }) => {
    const { addToCart } = useCartContext();
    const { layout } = useGlobalContext();

    const onBuyButtonClick = (e, item) => {
        // e.preventDefault();
        addToCart(item);
    };

    return (
        <>
            <div>
                <ul className={"products " + (ordering === 1 ? 'default-column' : ordering === 2 ? 'three-column' : ordering === 3 ? 'list-view' : '')}>
                    {
                        products.map((item, index) => (
                            <li key={index} className="product">
                                <div className="product-holder">
                                    <DiscountBadge item={item} />
                                    <Link to={"/product?id=" + item.id}>
                                        <Img loading="lazy" src={PUBLIC_CDN_URL + item.mainImg} alt="" />
                                    </Link>
                                    <div className="shop-action-wrap">
                                        <ShopAction item={item} />
                                    </div>
                                </div>
                                <div className="product-info">
                                    <h4>
                                        <Link to={"/product?id=" + item.id}>
                                            <TItleFont>{item.title}</TItleFont>
                                        </Link>
                                    </h4>
                                    <ProductPriceAmount item={item} />
                                    <p className="product-description">{parse(item.shortDescription)}</p>

                                    {ordering !== 3 ? (
                                        <BuyButton onClick={(e) => onBuyButtonClick(e, item)}>
                                            {layout.common.buy_button_label}
                                        </BuyButton>
                                    ) : (<></>)}

                                </div>
                            </li>
                        ))
                    }
                </ul>
                <ReactTooltip className='tool-tip' effect='solid' />
            </div>
        </>
    );
};

export default Products;

const TItleFont = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    margin-top: -10px;
    
    /* @media (max-width: 991px) {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;      
        margin-top: -10px;
    } */
`

const Img = styled.img`
    padding-top: 10px;
    object-fit: cover;
    /* object-position: center top; */
    width: 100%;
    height: 350px;

    @media (max-width: 500px) {
        height: 200px;
    }
`

const BuyButton = styled.span`
    font-family: "futura-pt-demi", sans-serif;
    background: ${props => props.isCheckout ? '#545454' : '#000'};
    width: 100%;
    font-size: 12px;
    font-weight: 500;
    padding: 12px 20px;
    color: #fff;
    color: white;
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-top: 10px;
    cursor: pointer;

    &:hover{
        background-color: #c2572b;
        color: #fff;
    }

    @media (max-width: 991px) {
        margin-top: 0px;
    }
`