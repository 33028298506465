import React, { createContext, useContext, useEffect, useState } from 'react';
import { useGlobalContext } from './GlobalProvider';
import { useStatsContext } from './StatsProvider';

const CartContext = createContext();

const CartProvider = ({ children }) => {
    const { layout, handelMiniCartStatus } = useGlobalContext()
    const { callAddToCart } = useStatsContext()

    const shippingSelectData = layout.cart.shipping.shipping_options;
    const freeShippingMinPrice = layout.cart.shipping.free_shipping_min_price;

    const [data, setData] = useState([]);
    const [shippingPrice, setShippingPrice] = useState(0);
    const [isFreeShipping, setIsFreeShipping] = useState(0);
    const [discountPrice, setDiscountPrice] = useState(0);
    const [shippingType, setShippingType] = useState(shippingSelectData[0])

    const [presumptiveGender, setPresumptiveGender] = useState(null)



    const addToCart = (cItem, qty = 1) => {
        const index = data.findIndex((item) => cItem.id === item.id);

        const newData = [...data];
        if (index === -1) {
            const newItem = {
                id: cItem.id,
                name: cItem.title,
                qty: qty,
                price: cItem.price,
                totalPrice: cItem.price * qty,
                img: cItem.mainImg,
                size: "",
                color: "",
                link: "/product?id=" + cItem.id,
                productData: cItem
            }

            newData.push(newItem);
            setData(newData)
        } else {
            const index = newData.findIndex(item => item.id === cItem.id)
            newData[index] = {
                id: cItem.id,
                name: cItem.title,
                qty: newData[index].qty + qty,
                price: cItem.price,
                totalPrice: (cItem.price * (parseInt(newData[index].qty) + parseInt(qty))),
                img: cItem.mainImg,
                size: "",
                color: "",
                link: "/product?id=" + cItem.id,
                productData: cItem
            };

            setData(newData)
        }

        handelMiniCartStatus();
        callAddToCart(cItem)
    }

    const removeFromCart = (cItem) => {
        const index = data.findIndex((item) => cItem.id === item.id);

        const newData = [...data];
        if (index === -1) {
            console.log("error there are no such item in cart");
        } else {
            const index = newData.findIndex(item => item.id === cItem.id)
            newData.splice(index, 1);

            setData(newData);
        }
    }

    const clearCartData = () => {
        setData([])
    }

    const icrementQtyHandler = (cItem) => {
        const newData = [...data];
        const index = newData.findIndex(item => item.id === cItem.id)
        newData[index].qty = newData[index].qty + 1;
        newData[index].totalPrice = parseInt(newData[index].totalPrice) + parseInt(newData[index].price);

        setData(newData)

    }

    const decrementQtyHandler = (cItem) => {
        const newData = [...data];
        const index = newData.findIndex(item => item.id === cItem.id)

        if (newData[index].qty - 1 > 0) {
            newData[index].qty = newData[index].qty - 1;
            newData[index].totalPrice = parseInt(newData[index].totalPrice) - parseInt(newData[index].price);
            setData(newData)
        }

    }

    const getCartTotalItems = () => {
        let totalItems = 0;
        for (var i = 0; i < data.length; i++) {
            totalItems = parseInt(totalItems) + parseInt(data[i].qty);
        }

        return totalItems;
    }

    const getSubtotalPrice = () => {
        let subtotalPrice = 0;
        for (var i = 0; i < data.length; i++) {
            subtotalPrice = subtotalPrice + (data[i].price * data[i].qty);
        }
        return subtotalPrice;
    }

    const addToLocalStorage = () => {
        localStorage.setItem('cartData', JSON.stringify(data))
    }

    const calcDiscountPrice = () => {
        const categorysDiscount = {}

        for (let i = 0; i < data.length; i++) {
            let item = data[i];

            if (categorysDiscount[item.productData.categoryId] === undefined) {
                categorysDiscount[item.productData.categoryId] = { qty: 0, totalPrice: 0 }
            }

            categorysDiscount[item.productData.categoryId].qty += item.qty;
            categorysDiscount[item.productData.categoryId].totalPrice += item.totalPrice;
        }

        let totalDiscount = 0;
        Object.entries(categorysDiscount).forEach(([key, value]) => {
            const discountArray = layout.categories.categories_data.find((item) => parseInt(item.id) === parseInt(key)).discount

            const currentDiscount = value.qty >= discountArray.length ? discountArray[discountArray.length - 1] : discountArray[value.qty - 1];

            totalDiscount += (parseInt(currentDiscount) / 100) * parseInt(value.totalPrice)
        });

        setDiscountPrice(totalDiscount)
    }

    useEffect(() => {
        const tmpData = localStorage.getItem('cartData');

        if (tmpData === null) {
            addToLocalStorage()
        } else {
            const obj = JSON.parse(tmpData || "[]");;
            setData(obj)
        }
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        addToLocalStorage();

        const isFree = freeShippingMinPrice <= getSubtotalPrice();
        setIsFreeShipping(isFree)
        setShippingPrice(isFree ? 0 : shippingType.price)

        calcDiscountPrice()


        if (data.length > 0) {
            setPresumptiveGender(data[0].productData.sex)
        }

    }, [data]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isFreeShipping) {
            setShippingPrice(0)
        } else {
            setShippingPrice(shippingType.price)
        }
    }, [shippingType])// eslint-disable-line react-hooks/exhaustive-deps

    const contextValue = {
        data,
        shippingPrice,
        shippingType,
        discountPrice,
        shippingSelectData,
        isFreeShipping,
        presumptiveGender,

        setShippingType,
        clearCartData,
        icrementQtyHandler,
        decrementQtyHandler,
        getCartTotalItems,
        getSubtotalPrice,
        removeFromCart,
        addToCart
    }

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    )
};

export const useCartContext = () => useContext(CartContext)

export default CartProvider;
