import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const ZoomOnHoverImage = ({ src }) => {
    const containerRef = useRef()

    const [scale, setScale] = useState(1);
    const [top, setTop] = useState('0%');
    const [left, setLeft] = useState('0%');


    const handleMouseOver = (e) => {
        setScale(1.5)
    }

    const handleMouseMove = (e) => {
        const { offsetWidth, offsetHeight } = containerRef.current

        const x = ((e.nativeEvent.offsetX / offsetWidth) * 100);
        const y = ((e.nativeEvent.offsetY / offsetHeight) * 100)

        setLeft(x + "%")
        setTop(y + "%")
    }

    const handleMouseOut = (e) => {
        setScale(1)
    }



    useEffect(() => {

    }, []);



    return (
        <ImgContainer
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onMouseMove={handleMouseMove} >
            <Img ref={containerRef}
                src={src}
                style={{
                    backgroundImage: src,
                    transformOrigin: (left + " " + top),
                    transform: 'scale(' + scale + ')'
                }}
            ></Img>
        </ImgContainer >
    );
};

export default ZoomOnHoverImage;

const ImgContainer = styled.div`
    position: relative;
    overflow: hidden;
    cursor: zoom-in;

    @media (max-width: 991px) {
        display: none;
    }
`

const Img = styled.img`
    top: 0;
    left: 0;
    width: 100%;
    height: 600px;
    background-repeat: no-repeat;
    background-size: cover;
    transition: transform 0.5s ease-out;
`
