import React, { useEffect } from 'react';
import Slider from "react-slick";
import ReactTooltip from 'react-tooltip';

import '../products/products.css';
import { Link } from "react-router-dom";
import { useGlobalContext } from '../../providers/GlobalProvider';
import ShopAction from './ShopAction';
import { useProductsContext } from '../../providers/ProductsProvider';
import { PUBLIC_CDN_URL, RecommendedCategoryId } from '../../defs';
import ProductPriceAmount from './ProductPriceAmount';
import DiscountBadge from './DiscountBadge';

const RecommendedProducts = ({ categoryId, pageIndex, sex }) => {
    const { layout } = useGlobalContext();
    const { recomendedProducts, getRecomendedProducts } = useProductsContext();

    /**
     * slider settings
     */
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 300,
        swipeToSlide: true,
        autoplaySpeed: 5000,
        focusOnSelect: false,
        draggable: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    useEffect(() => {
        getRecomendedProducts(categoryId, pageIndex, sex)
        // eslint-disable-next-line
    }, [])

    return (
        <>
            {/* start trendy-product-section */}
            <section className="trendy-product-section section-padding">

                <div className="container-1410">
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="section-title-s2">
                                <h2>{layout.recommended_products.title}</h2>
                            </div>
                            <Link className="more-products" to={"/category?id=" + RecommendedCategoryId}>
                                {layout.recommended_products.more_products_button_label}
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="products-wrapper">
                                <ul className="products product-row-slider">
                                    <Slider {...settings}>
                                        {
                                            recomendedProducts.map((item, index) => (
                                                <li key={index} style={{ margin: '10px' }} className="product">
                                                    <div className="product-holder">
                                                        {/* {parseInt(item.price) < parseInt(item.oldPrice) ?
                                                            <div className="product-badge discount">
                                                                {
                                                                    Math.round(((parseInt(item.price) - parseInt(item.oldPrice)) / parseInt(item.price)) * 100)
                                                                }
                                                                %</div> : ''
                                                        } */}
                                                        <DiscountBadge item={item} />

                                                        <Link to={"/product?id=" + item.id}>
                                                            <img loading="lazy" src={PUBLIC_CDN_URL + item.mainImg} alt="" />
                                                        </Link>

                                                        <div className="shop-action-wrap">
                                                            <ShopAction item={item} />
                                                        </div>
                                                    </div>
                                                    <div className="product-info">
                                                        <h4>
                                                            <Link to={"/product?id=" + item.id}>
                                                                {item.title}
                                                            </Link>
                                                        </h4>
                                                        <ProductPriceAmount item={item} />
                                                    </div>
                                                </li>
                                            ))
                                        }
                                    </Slider>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end container-1410 */}
            </section>
            {/* end trendy-product-section */}
            <ReactTooltip className='tool-tip' effect='solid' />
        </>
    )
}

export default RecommendedProducts