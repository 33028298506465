import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";

import App from './App';
import ApiProvider from './providers/ApiProvider';
import GlobalProvider from './providers/GlobalProvider';


/**
 * render App with reactDOM to root element
 */
ReactDOM.render(
    <Router>
        <ApiProvider>
            <GlobalProvider>
                <App />
            </GlobalProvider>
        </ApiProvider>
    </Router>
    ,
    document.getElementById('root')
);