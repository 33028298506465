import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import { useNavigate } from 'react-router-dom'
import PageTitle from '../../components/global/PageTitle';
// import ProductInfoTabs from '../../components/products/ProductInfoTabs';
import QuickView from '../../components/products/QuickView';
import RecentSingleProducts from '../../components/products/RecentSingleProducts';
import Layout from '../../components/global/Layout';
import { useLocation } from 'react-router-dom';

import './shop.css';
import { useGlobalContext } from '../../providers/GlobalProvider';
import { useCartContext } from '../../providers/CartProvider';
import { useProductsContext } from '../../providers/ProductsProvider';
import { PUBLIC_CDN_URL } from '../../defs';
import parse from 'html-react-parser'
import styled from 'styled-components';
import Preloader from '../../components/global/Preloader';
import ZoomOnHoverImage from './ZoomOnHoverImage';
import { useStatsContext } from '../../providers/StatsProvider';



const ShopSliderImages = () => {
    const [productCount, setProductCount] = useState(1);

    const { showQuickView, quickViewData, handelQuickViewClose, layout, formattedPriceWithCurrency } = useGlobalContext();
    const { addToCart } = useCartContext()
    const { callViewContent } = useStatsContext()
    const {
        getProductById,
        getCategoryNameById,
        currentProduct,
        currentCatergoryData,
        setCurrentCatergoryData
    } = useProductsContext()


    const navigate = useNavigate()

    const search = useLocation().search;
    const id = parseInt(new URLSearchParams(search).get("id"));

    const handleAddToCart = (e) => {
        e.preventDefault();
        addToCart(currentProduct, productCount);
    }

    useEffect(() => {
        if (id === null || isNaN(id)) {
            navigate('/404', { replace: true })
        } else {
            getProductById(id,
                (data) => {
                    callViewContent(data)

                    setCurrentCatergoryData(layout.categories.categories_data.find(i => i.id === data.categoryId))
                },
                () => {
                    navigate('/404', { replace: true })
                })


        }
        // eslint-disable-next-line
    }, [id]);

    /**
     * Handle Product Count
     */
    const HandleProductCount = (e, data) => {
        e.preventDefault();
        if (data === 'plus') {
            setProductCount(productCount + 1);
        } else {
            if (productCount > 1) {
                setProductCount(productCount - 1);
            } else {
                setProductCount(1);
            }
        }
    };

    /**
     * slider settings
     */
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplaySpeed: 2000,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
    };

    return (
        currentProduct === null ? <><Preloader /></> :
            <Layout>
                {showQuickView
                    ? <QuickView
                        data={quickViewData}
                        onQuickViewCloseClick={handelQuickViewClose}
                    />
                    : ''
                }

                {
                    currentCatergoryData === null ? <></> : <PageTitle name={layout.product_page.title} backgroundImg="/assets/images/page-title/jewelry-cover.jpg" />
                }
                {/* <PageTitle name={layout.product_page.title} /> */}

                {/* start shop-single-section */}
                <section className="shop-single-section section-padding">
                    <div className="container-1410">
                        <div className="row">
                            <div className="col col-md-6">
                                <div className="shop-single-slider slider-thumbnail">
                                    <Slider {...settings}>
                                        {
                                            currentProduct.gallery.map((item, index) => (
                                                <div key={index}>
                                                    <ZoomOnHoverImage src={PUBLIC_CDN_URL + item} />
                                                    <MobileWrapper>
                                                        <Img src={PUBLIC_CDN_URL + item} alt="" />
                                                    </MobileWrapper>
                                                </div>
                                            ))
                                        }
                                    </Slider>
                                    <div className="slider-nav"></div>
                                </div>
                            </div>
                            <div className="col col-md-6">
                                <div className="product-details">
                                    <h2>{currentProduct.title}</h2>
                                    <div className="price">
                                        <span className="current">{formattedPriceWithCurrency(currentProduct.price)}</span>
                                        <span className="old">{formattedPriceWithCurrency(currentProduct.oldPrice)}</span>
                                    </div>
                                    <div className="rating">
                                        <i className="fi flaticon-star" />
                                        <i className="fi flaticon-star" />
                                        <i className="fi flaticon-star" />
                                        <i className="fi flaticon-star" />
                                        <i className="fi flaticon-star-social-favorite-middle-full" />
                                        {
                                            (currentProduct.reviews.length > 0) ? <span>({layout.product_page.customer_review_label.replace('{0}', currentProduct.reviews.length)})</span> : <></>
                                        }

                                    </div>
                                    <p>{parse(currentProduct.shortDescription)}</p>
                                    {/* DESKTOP SECTION */}
                                    <DesktopWrapper>
                                        <div className="product-option">
                                            <form className="form">
                                                <div className="product-row">
                                                    <div className="touchspin-wrap">
                                                        <button
                                                            onClick={(e) => {
                                                                HandleProductCount(e, 'plus')
                                                            }} id="slider-thumbnail-touchspin-up" className="btn btn-default "
                                                            type="button"><i className="glyphicon glyphicon-chevron-up"></i>
                                                        </button>
                                                        <button
                                                            onClick={(e) => {
                                                                HandleProductCount(e, 'minus')
                                                            }}
                                                            id="slider-thumbnail-touchspin-down" className="btn btn-default "
                                                            type="button"><i className="glyphicon glyphicon-chevron-down"></i>
                                                        </button>
                                                        <input className="product-count" type="text"
                                                            value={productCount} onChange={(e) => {
                                                                const removedText = e.target.value.toString().replace(/[^0-9]+/, '');
                                                                setProductCount(removedText)
                                                            }}
                                                        />
                                                    </div>
                                                    <div>
                                                        <AddToCartButton onClick={handleAddToCart} type="submit">{layout.common.add_to_cart_button_label}</AddToCartButton>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="thb-product-meta-before">
                                            <div className="product_meta">
                                                <span className="sku_wrapper">
                                                    {layout.common.sku_label}:
                                                    <span className="sku">{currentProduct.id.toString().padStart(6, '0')}</span>
                                                </span>
                                                <span className="posted_in">
                                                    {layout.common.category_label}:
                                                    {
                                                        <a href={'/category?id=' + currentProduct.categoryId}> {getCategoryNameById(currentProduct.categoryId)}</a>
                                                    }
                                                </span>

                                            </div>
                                        </div>
                                    </DesktopWrapper>
                                    {/* MOBILE SECTION */}
                                    <MobileWrapper>
                                        <div className="thb-product-meta-before">
                                            <div className="product_meta">
                                                <span className="sku_wrapper">
                                                    {layout.common.sku_label}:
                                                    <span className="sku">{currentProduct.id.toString().padStart(6, '0')}</span>
                                                </span>
                                                <span className="posted_in">
                                                    {layout.common.category_label}:
                                                    {
                                                        <a href={'/category?id=' + currentProduct.categoryId}> {getCategoryNameById(currentProduct.categoryId)}</a>
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        <div className="touchspin-wrap">
                                            <CountInputWrapper>
                                                {layout.product_page.product_qty_label}
                                                <CountInput type="text" onChange={(e) => {
                                                    const removedText = e.target.value.toString().replace(/[^0-9]+/, '');
                                                    setProductCount(removedText)
                                                }} value={productCount} />
                                            </CountInputWrapper>
                                        </div>
                                        <FixedBottomContainer>
                                            <MobileAddToCartButton onClick={handleAddToCart} type="submit">{layout.common.add_to_cart_button_label}</MobileAddToCartButton>
                                        </FixedBottomContainer>
                                    </MobileWrapper>
                                </div>
                            </div>
                            {/* end col */}
                        </div>
                        {/* end row */}
                        {/* <div className="row">
                            <div className="col col-md-8 col-md-offset-2">
                                <ProductInfoTabs item={data} />
                            </div>
                        </div> */}
                        {/* end row */}
                        <div className="row">
                            <div className="col col-xs-12">
                                <RecentSingleProducts categoryId={currentProduct.categoryId} />
                            </div>
                        </div>
                    </div>
                    {/* end of container */}
                </section>
                {/* end of shop-single-section */}
            </Layout>
    );
}

export default ShopSliderImages

const AddToCartButton = styled.button`
    transition: none;
    &:active{
        background-color: #c2572b;
    }
`

const DesktopWrapper = styled.div`
    @media (max-width: 991px) {
        display: none;
    }
`

const MobileWrapper = styled.div`
    display: none;

    @media (max-width: 991px) {
        display: flex;
        justify-content: space-between;
    }
`

const CountInputWrapper = styled.span`
    display: flex;
    width: 150px;
`

const CountInput = styled.input.attrs({ type: 'text' })`
    text-align: right;
    margin-left: 20px;
`

const FixedBottomContainer = styled.div`
    position: fixed;
    background-color: white;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    z-index: 999;
`

const MobileAddToCartButton = styled.button`
    width: 100%;
`
const Img = styled.img`
    object-fit: cover;
    width: 100%;
    height: 500px;
`