import React, { Fragment } from 'react';
import Slider from "react-slick";
import '../Hero/hero.css';
import { useGlobalContext } from '../../providers/GlobalProvider';

const Hero3 = () => {

    const { layout } = useGlobalContext();
    /**
     * slider settings with parameters
     * @type {{fade: boolean, dots: boolean, infinite: boolean, slidesToScroll: number, focusOnSelect: boolean, slidesToShow: number, lazyLoad: boolean, speed: number, autoplay: boolean}}
     */
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        focusOnSelect: false,
        fade: true,
        lazyLoad: false
    };

    return (
        <>
            <section className="hero-3">
                <Slider {...settings}>
                    {
                        layout.hero_3.hero_data.map((item, index) => (
                            <div key={index}>
                                <div className="swiper-slide" style={{ background: `${item.color}` }}>
                                    <div className="slide-inner">
                                        <div className="slide-title">
                                            <h2>{item.title}</h2>
                                        </div>
                                        <div className="clearfix" />
                                        <div className="slide-img">
                                            <img src={process.env.PUBLIC_URL + item.img} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </Slider>
            </section>
        </>
    )
}

export default Hero3