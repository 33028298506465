import React from 'react';
import styled from 'styled-components';
import { PUBLIC_CDN_URL } from '../../defs';
import { useGlobalContext } from '../../providers/GlobalProvider';

const MiniCartItem = ({ item, onNavClick }) => {
    const { layout, formattedPriceWithCurrency } = useGlobalContext();

    return (
        <>
            <div className="mini-cart-item clearfix">
                <div className="mini-cart-item-image">
                    <ActionLink onClick={() => onNavClick(item.link)}>
                        <img src={PUBLIC_CDN_URL + item.img} alt="" />
                    </ActionLink>
                </div>
                <div className="mini-cart-item-des">
                    <ActionLink onClick={() => onNavClick(item.link)}>{item.name}</ActionLink>
                    <span className="mini-cart-item-quantity">{layout.header.header_right.mini_cart.quantity_label} {item.qty}</span>
                    <span className="mini-cart-item-price">{formattedPriceWithCurrency(item.price)}</span>
                </div>
            </div>
        </>
    );;
};

export default MiniCartItem;

const ActionLink = styled.a`
    cursor: pointer;
`;