import React from 'react';
import { NavLink } from 'react-router-dom';
import Layout from '../../components/global/Layout';
import { useGlobalContext } from '../../providers/GlobalProvider';
import styled from 'styled-components';
import parse from 'html-react-parser'

const MoneyBackConditions = () => {

    const { layout } = useGlobalContext();

    return (
        <Layout>
            <section>
                <Wrapper>
                    <h3>{layout.money_back_conditions.title}</h3>
                    <br />
                    <p>{parse(layout.money_back_conditions.description)}</p>
                    <br />
                    <NavLink to="/" style={{ padding: '15px 25px' }} className="theme-btn">{layout.how_to_order.back_button_label}</NavLink>
                </Wrapper>
            </section>
        </Layout>
    );
}

export default MoneyBackConditions

const Wrapper = styled.div`
    margin: 150px 400px;

    @media (max-width: 991px) {
        margin: 150px 20px 0px;
    }
`