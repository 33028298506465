import React from 'react';

import Hero3 from '../../components/Hero/Hero3';
import QuickView from '../../components/products/QuickView';
import Feature1 from '../../components/features/Feature1';
import Feature2 from '../../components/features/Feature2';
import Feature3 from '../../components/features/Feature3';
import OurProducts from '../../components/products/OurProducts';
import Layout from '../../components/global/Layout';
import { useGlobalContext } from '../../providers/GlobalProvider';


const Style3 = () => {
    const { showQuickView, quickViewData, handelQuickViewClose } = useGlobalContext();

    return (
        <Layout>
            {showQuickView
                ? <QuickView
                    data={quickViewData}
                    onQuickViewCloseClick={handelQuickViewClose}
                />
                : ''
            }

            <Hero3 />
            <Feature2 />
            <OurProducts />
            <Feature3 />
            <Feature1 extraClass='home-feature-section-s2' />
        </Layout>
    );
}

export default Style3