import React from 'react';

import PageTitle from '../../components/global/PageTitle';
import CartItem from "../../components/cart/CartItem";
import Coupon from "../../components/cart/Coupon";
import CalculatedShipping from "../../components/cart/CalculatedShipping";

import './cart.css';
import Layout from '../../components/global/Layout';
import { useGlobalContext } from '../../providers/GlobalProvider';
import { useCartContext } from '../../providers/CartProvider';

const Cart = () => {
    const { layout } = useGlobalContext()
    const { data } = useCartContext()

    return (
        <Layout>
            <PageTitle name={layout.cart.title} />

            {/* start cart-section */}
            <section className="cart-section woocommerce-cart section-padding">
                <div className="container-1410">
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="woocommerce">
                                <form action="/" method="post">
                                    <table className="shop_table shop_table_responsive cart">
                                        <thead>
                                            <tr>
                                                <th className="product-remove">&nbsp;</th>
                                                <th className="product-thumbnail">&nbsp;</th>
                                                <th className="product-name">{layout.cart.orders_table.product_label}</th>
                                                <th className="product-price">{layout.cart.orders_table.price_label}</th>
                                                <th className="product-quantity">{layout.cart.orders_table.quantity_label}</th>
                                                <th className="product-subtotal">{layout.cart.orders_table.total_label}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data.map((item, index) => (
                                                    <CartItem key={index} data={item} />
                                                ))
                                            }
                                            <Coupon />
                                        </tbody>
                                    </table>
                                </form>
                                <div className="cart-collaterals">
                                    <CalculatedShipping />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end cart-section */}

        </Layout>
    );
}

export default Cart