import React from 'react';
import { useProductsContext } from '../../providers/ProductsProvider';
import ProductItem from "./ProductItem";


const OurProductsTabsContent = ({ tabSelected }) => {
    const { products } = useProductsContext()


    /**
     * Shuffles array in place.
     * @param {Array} a items An array containing the items.
     */
    const shuffle = (a) => {
        let j, x, i;
        for (i = a.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            x = a[i];
            a[i] = a[j];
            a[j] = x;
        }
        return a;
    };

    const shiffledProducts = shuffle(products);

    return (
        <>
            <div className="tab-content">
                <div className={"tab-pane " + (tabSelected === 1 ? 'active' : 'fade')} id="tab-1">
                    <ul className="products">
                        {shiffledProducts.map((item, index) => (
                            <ProductItem key={index} item={item} />
                        ))}
                    </ul>
                </div>

                <div className={"tab-pane " + (tabSelected === 2 ? 'active' : 'fade')} id="tab-2">
                    <ul className="products">
                        {shiffledProducts.map((item, index) => (
                            <ProductItem key={index} item={item} />
                        ))}
                    </ul>
                </div>

                <div className={"tab-pane " + (tabSelected === 3 ? 'active' : 'fade')} id="tab-3">
                    <ul className="products">
                        {shiffledProducts.map((item, index) => (
                            <ProductItem key={index} item={item} />
                        ))}
                    </ul>
                </div>

            </div>
        </>
    )
}

export default OurProductsTabsContent