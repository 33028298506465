import React from 'react';
import { NavLink } from 'react-router-dom';
import Layout from '../../components/global/Layout';
import './thankYouPage.css';
import { useGlobalContext } from '../../providers/GlobalProvider';
import { useOrderContext } from '../../providers/OrderProvider';
import Preloader from '../../components/global/Preloader';
import RecommendedProducts from '../../components/products/RecommendedProducts';
import { useCartContext } from '../../providers/CartProvider';
import { RecommendedCategoryId } from '../../defs';

const ThankYouPage = () => {

    const { layout } = useGlobalContext();
    const { orderId, isOrdering } = useOrderContext()
    const { presumptiveGender } = useCartContext()

    return (
        <Layout>
            {/* start error-404-section */}
            {/* <section className="error-404-pg error-404-section section-padding"> */}
            <section className="error-404-section thank-you-page-section-padding">
                <div className="container-1410">
                    <div className="error-404-area">
                        <div className="error-message">
                            {isOrdering ?
                                (
                                    <>
                                        <Preloader />
                                    </>
                                ) : (
                                    <>
                                        <h3>{layout.thank_you_page.title}</h3>
                                        <p>{layout.thank_you_page.description}</p>
                                        {
                                            orderId > 0 ? (
                                                <h3>{layout.thank_you_page.order_number_label.replace('{0}', orderId.toString().padStart(6, '0'))}</h3>
                                            ) : (<></>)
                                        }
                                        <NavLink to="/" className="theme-btn">{layout.thank_you_page.back_button_label}</NavLink>
                                    </>
                                )
                            }

                        </div>

                    </div>

                </div>
            </section >

            {
                presumptiveGender !== null ?
                    (
                        <RecommendedProducts categoryId={RecommendedCategoryId} pageIndex={0} sex={presumptiveGender} />
                    ) : (
                        <></>
                    )
            }

            {/* end error-404-section */}
        </Layout >
    );
}

export default ThankYouPage