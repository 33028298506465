import React from 'react';
import { useGlobalContext } from '../../providers/GlobalProvider';
import Feature1Item from "./Feature1Item";

const Feature1 = ({ extraClass }) => {

    const { layout } = useGlobalContext()

    return <>
        {/* start feature-section */}
        <section className={"feature-section home-feature-section " + extraClass}>
            <div className="container-1410">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="features clearfix">
                            {layout.feature.items.map((item, index) => (
                                <Feature1Item key={index} icon={item.icon} title={item.title} text={item.text} />
                            ))}
                            {/* <Feature1Item icon="pe-7s-car" title="Free Shipping" text="Around the world" />
                            <Feature1Item icon="pe-7s-paper-plane" title="PE-7S-ALARM" text="Customer support" />
                            <Feature1Item icon="pe-7s-wallet" title="Money returns" text="Guarantee of money return" />
                            <Feature1Item icon="pe-7s-rocket" title="Store search" text="Quick Store Search" /> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* end feature-section */}
    </>;
};

export default Feature1;