import React, { Fragment } from 'react';
import { useGlobalContext } from '../../providers/GlobalProvider';


const HeaderLeft = () => {
    const { showSideInfo, handelSideInfoStatus, layout } = useGlobalContext();


    return (
        <>
            <div className="header-left">
                <div className="side-info-bars" onClick={handelSideInfoStatus}>
                    <span />
                    <span />
                    <span />
                </div>
                <div className={"side-info-content " + (showSideInfo ? 'toggle-side-info' : '')}>
                    <button className="btn side-info-close-btn" onClick={handelSideInfoStatus}><i className="ti-close" />
                    </button>
                    <div className="logo">
                        <img src={process.env.PUBLIC_URL + "/assets/images/slidbar-logo.png"} alt="" />
                    </div>
                    <div className="text">
                        <p>{layout.header.header_left.description}</p>
                        <ul className="info">
                            <li>{layout.header.header_left.contact_us_label}</li>
                            <li>{layout.header.header_left.mail_us_label}</li>
                        </ul>
                        <ul className="social-links">
                            <li><a href={layout.common.facebook_link} rel="noopener noreferrer" target="_blank">
                                <i className="ti-facebook" /></a>
                            </li>
                            <li><a href={layout.common.instagram_link} rel="noopener noreferrer" target="_blank">
                                <i className="ti-instagram" /></a>
                            </li>
                            <li><a href={layout.common.twitter_link} rel="noopener noreferrer" target="_blank">
                                <i className="ti-twitter-alt" /></a>
                            </li>
                            <li><a href={layout.common.youtube_link} rel="noopener noreferrer" target="_blank">
                                <i className="ti-youtube" /></a>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* <div className="search-area">
                <form>
                    <button type="submit"><i className="fi flaticon-search"/></button>
                    <input type="text" placeholder="Search for.."/>
                </form>
            </div> */}
            </div>
        </>
    );
};

export default HeaderLeft;